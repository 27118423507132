import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  constructor() {}

  public strapline: string;

  ngOnInit(): void {
    this.sloganPicker(this.getRandomInt(1, 5));

    this.strapline;
  }

  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  sloganPicker(slogan): string {
    switch (slogan) {
      case 1:
        this.strapline = 'HOIST YOUR JOLLY ROGER AND GET SOME!!!';
        return this.strapline;

      case 2:
        this.strapline = 'BEER FOR BUOYS AND GULLS';
        return this.strapline;
      case 3:
        this.strapline = "IF YOU LIKE BARNACLES YOU'LL LOVE OUR BEER!";
        return this.strapline;
      case 4:
        this.strapline = 'IS BEER GOOD FOR YOUR BRAIN? PROBABLY ...';
        return this.strapline;
      case 5:
        this.strapline = 'SAILING THE SEVEN SEAS, FROM BEER TO ETERNITY';
        return this.strapline;
    }

    return slogan;
  }
}
