<div class="header-container">
  <div class="logo-name-container">
    <div class="logo">
      <img src="../../assets/sb-logo.png" alt="SB LOGO" />
    </div>
    <div class="company-name">
      <span>the</span> <br />
      <span>SALTY BUOY</span> <br />
      <span style="font-size: 1.8rem">BEER COMPANY</span>
    </div>
  </div>
  <div class="strapline">
    <p>{{ strapline }}</p>
  </div>
  <app-header-nav> </app-header-nav>
</div>
