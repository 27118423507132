<div class="full-box-white">
  <h2>About the Company</h2>

  <p>
    Lipsum dolor sit amet, consectetur adipiscing elit. Donec dignissim, odio in
    egestas venenatis, turpis ante ullamcorper enim, quis bibendum ipsum sapien
    a nisl. Lipsum dolor sit amet, consectetur adipiscing elit. Donec dignissim,
    odio in egestas venenatis, turpis ante ullamcorper enim, quis bibendum ipsum
    sapien a nisl. turpis ante ullamcorper enim, quis bibendum ipsum sapien a
    nisl.
  </p>
</div>

<div class="full-box-white">
  <h2>Meet the Crew</h2>

  <div class="crew-member">
    <img src="" alt="" />
    <div class="crew-member-text">
      <h4>The Captain</h4>
      <p>
        Loyal, trustworthy and devoted to his ship and his crew. Integrity
        personified.
      </p>
    </div>
  </div>

  <div class="crew-member">
    <img src="" alt="" />
    <div class="crew-member-text">
      <h4>The Quarterm</h4>
      <p>
        Two steady hands on the wheel. Unwavering, dependable and always on
        time.
      </p>
    </div>
  </div>

  <div class="crew-member">
    <img src="" alt="" />
    <div class="crew-member-text">
      <h4>The Cook</h4>
      <p>
        Serves all manner of slops to the crew. You’ll find him below decks, in
        the shadows, plotting. Always plotting.
      </p>
    </div>
  </div>
</div>
