<div id="header-nav-container">
  <ul class="nav navbar-nav">
    <li><a [routerLink]="['']">Home</a></li>
    <li><a [routerLink]="['/about']">About</a></li>
    <li><a href="#">Beers </a></li>
    <li><a href="#">Breweries </a></li>
    <li><a href="#">Buy </a></li>
    <li><a href="#">Tunes </a></li>
  </ul>

  <div class="mobile">
    <span (click)="openNav()">Menu</span>

    <div id="navBar" class="overlay">
      <!-- Button to close the overlay navigation -->
      <a href="javascript:void(0)" class="closebtn" (click)="closeNav()"
        >&times;</a
      >
      <!-- Overlay content -->
      <div class="overlay-content">
        <a [routerLink]="['']">Home</a>
        <a [routerLink]="['/about']">About</a>
        <a href="#">Beers</a>
        <a href="#">Breweries</a>
        <a href="#">Buy</a>
        <a href="#">Tunes</a>
      </div>
    </div>
  </div>
</div>
