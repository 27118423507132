<div class="sky-gradient">
  <div class="bird-container bird-container--one">
    <div class="bird bird--one"></div>
  </div>

  <div class="bird-container bird-container--two">
    <div class="bird bird--two"></div>
  </div>

  <div class="bird-container bird-container--three">
    <div class="bird bird--three"></div>
  </div>

  <div class="bird-container bird-container--four">
    <div class="bird bird--four"></div>
  </div>

  <div class="bird-container bird-container--five">
    <div class="bird bird--five"></div>
  </div>

  <router-outlet></router-outlet>

  <div class="waves">
    <img src="../../assets/waves-new.png" alt="Waves" />
  </div>
</div>
