import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-nav',
  templateUrl: './header-nav.component.html',
  styleUrls: ['./header-nav.component.scss']
})
export class HeaderNavComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  public openNav() {
    document.getElementById('navBar').style.width = '100%';
  }
  public closeNav() {
    document.getElementById('navBar').style.width = '0%';
  }
}
