<div class="footer-container">
  <div class="contact-info">
    <h2>Contact Us</h2>
    <a class="email" href="mailto:ahoy@thesaltybuoy.com"
      >ahoy@thesaltybuoy.com</a
    >
    <!--<a href="tel:07712345678" class="phone">07712345678</a>

    <div class="social-media">
      <a href=""><fa-icon [icon]="faFacebook"></fa-icon></a>
      <a href=""><fa-icon [icon]="faTwitter"></fa-icon></a>
      <a href=""><fa-icon [icon]="faInsta"></fa-icon></a>
    </div>-->
  </div>

  <h4>Brewed by Jack Deazley &copy; 2020</h4>

  <div class="kraken"></div>
</div>
